
#welcomeimagecontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
    min-height: 700px;
    background-color: black;
}

#logo {
    position: absolute;
    background-image: url('../files/immlogo.png');
    background-size: cover;
    margin-left: 105px;
    width: 220px;
    height: 80px;
    top: 70px;
    z-index: 20;
}

#azab {
    opacity: 1;
    filter: saturate(40%);
    background-repeat: no-repeat;
    background-image: url('../files/azab.jpg');
    min-width: 550px;
    width: 55%;
    height: 100%;
    background-size: cover;
    background-position: 0px 0px;
    position: relative;
    display: inline-block;
}

#sparks {
    background-image: url('../files/sparks.jpg');
    background-size: cover;
    position: absolute;
    height: 100%;
    min-height: 700px;
    width: 50%;
    min-width: 720px;
}

#gradientleft {
    position: absolute;
    left: 0px;
    top: 0px;
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.301), rgba(0, 0, 0, 0.842));
    width: 100%;
    height: 100%;
    z-index: 2;
}

#gradientright {
    position: absolute;
    left: -100px;
    top: 0px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.842));
    width: 100px;
    height: 100%;
    z-index: 1;
}

#welcomecontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 720px;
}

#Wcount {
    margin-left: 105px;
    display: flex;
    flex-direction: row;
    z-index: 20;
}

#Whashtag {
    margin-left: 105px;
    color: #726D63;
    font-family: Caled;
    font-size: 20px;
    z-index: 20;
}

#Wdrop {
    margin-left: 105px;
    font-family: Anton;
    color: #8E887A;
    font-size: 79px;
    margin-top: -8px;
    margin-bottom: -2px;
    z-index: 20;
}

.Wtimebox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
}

.Wtime {
    color: #474E57;
    font-size: 48px;
    font-family: CaledR;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 100px;
    border: 1px solid #726D63;
}

.Wtimetype {
    font-size: 18px;
    font-family: Caled;
    color: #726D63;
    margin-top: 7px;
}

#Wsocials {
    z-index: 40;
    margin-left: 105px;
    margin-top: 27px;
    display: flex;
    flex-direction: row;
}

#Wdiscord {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8E887A;
    width: 190px;
    height: 46px;
    border-radius: 6px;
    font-family: Caled;
    margin-right: 20px;
    transition: ease-in-out .5s;
    color: black;
}


.landingbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #BBA269;
    width: 190px;
    height: 46px;
    border-radius: 6px;
    font-family: Caled;
    margin-right: 20px;
    transition: ease-in-out .5s;
    color: black;
}


.landingbutton:hover {
    box-shadow: 0px 0px 10px 4px #BBA269;
    background-color: #ECC977;
}
#Wtwitter {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8E887A;
    width: 200px;
    height: 46px;
    border-radius: 6px;
    font-family: Caled;
    color: black;
    transition: ease-in-out .5s;
}

#Wdiscord:hover {
    box-shadow: 0px 0px 10px 4px #8E887A;
    background-color: #b4ad9d;
}

#Wtwitter:hover {
    box-shadow: 0px 0px 10px 4px #8E887A;
    background-color: #b4ad9d;
}

@media only screen and (max-width: 500px) {

    #welcomeimagecontainer {
        height: 100vh;
        min-height: 900px;
    }

    #sparks {
        height: 100%;
        min-height: 850px;
        min-width: 100%;
    }

    #azab {
        display: none;
    }
    #gradientleft {
        display: none;
    }
    #Wsocials {
        flex-direction: column;
        margin-left: 0px;
    }

    #Whashtag {
        margin-left: 0px;

    }

    #Wdiscord {
        margin-bottom: 20px;
        margin-right: 0px;
    }

    .landingbutton {
        margin-bottom: 20px;
        margin-right: 0px;
    }
    #Wtwitter {
        width: 190px;
        margin-bottom: 20px;
    }
    
    #Wdrop {
        text-align: center;
        margin-left: 0px;
    }

    #welcomecontainer {
        min-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #Wcount {
        align-items: center;
        margin-left: 0px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .Wtimebox {
        margin-left: 20px;
    }

    .Wtimetype {
        margin-bottom: 10px;
    }

    #logo {
        position: static;
        margin-left: 0px;
        margin-bottom: 30px;
        margin-top: 25px;
    }
}