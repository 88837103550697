#navcontainer {
    width: 100%;
    height: 90px;
    min-height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-family: Caled;
    // position: absolute;
  }

  .selected {
      font-family: CaledB;
    //   color: black;
    //   background-color: #8e887ac7;
    //   border-radius: 20;
    //   padding: 2px;
    //   box-shadow: 0px 0px 10px 4px #8E887A;
  }

.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 3%;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #555868;
  }
  
  /* Position and sizing of clickable cross button */

.bm-cross-button {
    top: 20px !important;
    right: 27px !important;
    height: 39px !important;
    width: 36px !important;
  }

/* This targets the actual elements that make up the cross */
.bm-cross {
    width: 5px !important;
    height: 30px !important;
}
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    top: 0;
    height: 100%;
    display: block !important;
    width: 100vw !important;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #000000;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #000000;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    background-color: #000000;
    display: flex;
    justify-content: center;
    padding-right: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  #navulright {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: 0px;
    margin-right: calc(3%);
  }

  #navulleft {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: 0px;
    margin-left: calc(3%);
  }

  .navli {
    line-height: 180%;
    margin-right: 20px;
    color: #BAA577;
  }

  .walletbutton {
      cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(180deg, #BBA269 4.69%, #EFCB78 46.35%, #B59752 100%);;
    width: 170px;
    font-family: Caled;
    transition: box-shadow ease-in-out .6s;
    color: black;
    opacity: .8;
}

.walletbutton:hover {
    box-shadow: 0px 0px 10px 4px #8E887A;
    background-color: #b4ad9d;
}

@media only screen and (min-width: 1000px) {
    #navulright {
        margin-right: 5%;
      }

      .navli {
        margin-right: 40px;

      }
    
      #navulleft {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        padding: 0px;
        margin-left: 5%;
      }

}

@media only screen and (min-width: 1200px) {
    #navulright {
        margin-right: 6.4%;
      }

      .navli {
        margin-right: 50px;
        font-family: Caled;
      }
    
      #navulleft {
        margin-left: 6.4%;
      }
}

@media only screen and (min-width: 1400px) {
    #navulright {
        margin-right: 8.8%;
      }

      .navli {
        margin-right: 70px;

      }
    
      #navulleft {
        margin-left: 8.8%;
      }
}


@media only screen and (max-width: 900px) {
  #navcontainer {
    font-size: 18px;
  }
}


@media only screen and (max-width: 700px) {
  #navulright.bm-item {
    margin-left: 8%;
    font-size: 22px;
    margin-top: 80px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    }

    .navli {
      margin-bottom: 20px;
    }

    .walletbutton {
      font-size: 20px;
    }

    #mobiledisc {
      margin-right: 25px;
    }
    
    #mobiletwit {
      margin-right: 25px;
    }
}

