#footercontainer {
    display: flex;
    height: 30vw;
    min-height: 250px;
    width: 100vw;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}

#footerc2 {
    width: calc(70%);
    display: flex;
    position: absolute;
    bottom: 7vw;
    flex-direction: column;
    color: #B2A88E !important;
    margin-left: 15%;
    font-size: 18px;
}

#copysocials {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#footerlogo {
    background-image: url('../files/immlogo.png');
    background-size: cover;
    width: 15vw;
    min-width: 140px;
    min-height: 53px;
    height: 5.7vw;
    margin-bottom: 20px;
    opacity: .7;
}

.footerlink {
    color: #B2A88E !important;
    margin-left: 20px;
    font-size: 22px;
}

#footerline {
    width: 100%;
    height: 2px;
    background-color: #B2A88E;
    margin-bottom: 20px;
}

#Fcopyright {
    width: 70%;
    font-family: Caled;
}

#footerimages {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 22vw;
    min-height: 150px;
    opacity: .35;
    position: relative;
}

#imggrad {
    position: absolute;
    top: 0px;
    background-image: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    height: 100%;
}

#imggrad2 {
    position: absolute;
    top: -40%;
    background-image: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
    width: 100vw;
    height: 40%;
}

#fi1 {
    width: 20%;
    height: 100%;
}
#fi2 {
    width: 20%;
    height: 100%;
}
#fi3 {
    width: 20%;
    height: 100%;
}
#fi4 {
    width: 20%;
    height: 100%;
}
#fi5 {
    width: 20%;
    height: 100%;
}

@media only screen and (max-width: 800px) {
    #footerimages {
        display: none;
        
    }
    #Fsocialcontainer {
        width: 110px;
    }
    
    #imggrad {
        display: none
    }

    #imggrad2 {
        display: none;
    }

    .footerlink {
        color: #B2A88E !important;
        margin-left: 10px;
        font-size: 20px;
    }

    #footerc2 {
        font-size: 15px;
    }

    
}