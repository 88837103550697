#roadmapbg {
    width: 85vw;
    background-color: rgba(0, 0, 0, 0) !important;
    margin-bottom: 100px;
}


@media only screen and (min-width: 1600px) {
    #roadmapbg {
        background-color: rgba(0, 0, 0, 0) !important;
        width: 70vw;
        margin-bottom: 140px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 900px) {
    #roadmapbg {
        background-color: rgba(0, 0, 0, 0) !important;
        width: 95vw;
        margin-top: 90px;
        margin-bottom: 20px;
    }
}