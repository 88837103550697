#maincontainer {
    background-image: url('../files/concrete.png');
    background-size: contain; 
    background-repeat: repeat;
    position: relative;
    width: 100vw;
}

#mainbg {
    background-image: url('../files/mainbg.jpg');
    background-size: cover;
    top: 90px;
    height: 800px;
    width: 100vw;
    right: 0%;
    background-position: 40% 100%;
    z-index: -1;
    position: absolute;
    opacity: .64;
}

#aboutbg {
    top: 1690px;
    height: 700px;
    width: 100vw;
    z-index: -1;
    position: absolute;
    opacity: .43;
}

#main {
    background-color: black;
    position: absolute;
    z-index: 0;
}

#Mtopgradient {
    z-index: -1;
    position: absolute;
    top: 90px;
    background-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    height: 7%;
}
#Atopgradient {
    z-index: -1;
    position: absolute;
    top: 1690px;
    background-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    height: 100px;
}
#Abottomgradient {
    z-index: -1;
    position: absolute;
    top: 2290px;
    background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    height: 100px;
}

@media only screen and (max-width: 700px) {
    #mainbg {
        top: 0px;
    }

    #Mtopgradient {
        top: 0px;
    }

    #aboutbg {
        top: 1600px;
    }

    #Atopgradient {
        top: 1600px;
    }
    #Abottomgradient {
        top: 2200px;
    }
}

#carouselcontainer {
    position: relative;
    width: 100vw;
    height: 600px;
    overflow: hidden;
}

.Ccenter {
    width: 400px !important;
    border: 4px solid #8E887A;
    box-shadow: 0px 0px 30px 20px #000000;
    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -55%);
}

.Ccenterleft {

    width: 340px;
    border: 4px solid #8E887A;
    box-shadow: 0px 0px 30px 20px #141414;
    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-130%, -55%);
}

.Ccenterright {
    width: 340px;
    border: 4px solid #8E887A;
    box-shadow: 0px 0px 30px 20px #141414;
    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(30%, -55%);
}

.Cleft {

    width: 270px !important;
    border: 4px solid #8E887A;
    box-shadow: 0px 0px 30px 20px #141414;
    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-220%, -55%);
}

.Cright {
    width: 270px !important;
    border: 4px solid #8E887A;
    box-shadow: 0px 0px 30px 20px #141414;
    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(130%, -55%);
}

#band {
    width: 100vw;
    height: 70px;
    background-image: linear-gradient(90deg, rgba(146,120,72,1) 0%, rgba(186,165,119,1) 15%, rgba(178,168,142,1) 50%, rgba(218,190,128,1) 76%, rgba(146,120,72,1) 100%);;

    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */

    transform: translate(0%, -55%);
}

#carouselright {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    width: 48px;
    height: 48px;
    border: 2px solid #DABE80;
    color: #DABE80;
    background-color: black;
    box-shadow: 0px 0px 10px 3px #141414;
    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(800%, -55%);
    cursor: pointer;
}

#carouselleft {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    width: 48px;
    height: 48px;
    border: 2px solid #DABE80;
    color: #DABE80;
    background-color: black;
    box-shadow: 0px 0px 10px 3px #141414;
    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-890%, -55%);
}

@media only screen and (max-width: 1070px) {
    #carouselleft {
        transform: translate(-580%, -55%);
    }

    #carouselright {
        transform: translate(490%, -55%);
    }
}


@media only screen and (max-width: 700px) {

    .Ccenter {
        width: 300px !important;
        
    }

    .Ccenterleft {
        width: 200px !important;
        
    }

    .Ccenterright {
        width: 200px !important;
        
    }

    #carouselleft {
        transform: translate(-115%, -520%);
    }

    #carouselright {
        transform: translate(25%, -520%);
    }

}
