body, html {
    margin: 0;
}

a {
    text-decoration: none;
    color: #BAA577;
}

@font-face {
    font-family: Caled;
    src: url('../files/NewCaledoniaLTStdSmBold.woff');
  }

  @font-face {
    font-family: CaledB;
    src: url('../files/NewCaledoniaLTStdBold.woff');
  }

  @font-face {
    font-family: CaledR;
    src: url('../files/NewCaledoniaLTStdRg.woff');
  }

  @font-face {
    font-family: Anton;
    src: url('../files/Anton-Regular.ttf');
  }

  @font-face {
    font-family: MGS2;
    src: url('../files/MGS2.ttf');
  }


  #fallbackcontainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100vh;
      width: 100vw;
      min-width: 300px;
      min-height: 300px;
      background-image: linear-gradient(to top, rgb(24, 24, 24), rgba(61, 61, 61, 1));
  }

  #fallbacktext {
      color: #8E887A;
      font-family: Anton;
      font-size: 55px;
      text-align: center;
  }

  #fallbackbutton {
      font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #8E887A;
        width: 190px;
        height: 46px;
        border-radius: 6px;
        font-family: Caled;
        margin-top: 40px;
        transition: ease-in-out .5s;
  }

  #fallbackbutton:hover {
      box-shadow: 0px 0px 10px 4px #8E887A;
      background-color: #b4ad9d;
  }