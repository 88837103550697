/* Vector 5 */

.Pcontainer {
    width: 100vw;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Ptoprow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin-bottom: 70px;
}

.partnerleft {
    background-image: url('../files/partnerleft.png');
    background-size: cover;
    height: 20px;
    width: 320px;
}

.Ptitle {
    font-family: MGS2;
    margin-top: 60px;
    letter-spacing: 8px;
    font-size: 55px;
    color: #B2A88E;
    margin-left: 27px;
    margin-right: 20px;
}

.partnerright {
    background-image: url('../files/partnerright.png');
    background-size: cover;
    height: 20px;
    width: 320px;
}

#logowrapper {
    margin-top: -10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

#BLUpartner {
    background-image: url('../files/BLU.png');
    background-size: cover;
    width: 129px;
        height: 140px;
    margin-right: 100px;
    position: relative;
}

#WWpartner {
    background-image: url('../files/WW.png');
    background-size: cover;
    width: 140px;
        height: 129px;
    margin-left: 100px;
    position: relative;
}

#WWtext {
    font-size: 30px;
    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, 170%);
    text-align: center;
    display: none;
}

#BLUtext {
    font-size: 30px;
    display: none;
    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */
    text-align: center;
    transform: translate(-50%, 170%);
}

.Acontainer {
    width: 100vw;
    height: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#Atextwrapper {
    width: 900px;
    margin-top: 10px;
    text-align: center;
    color: #8E887A;
    font-size: 24px;
    font-family: CaledR;
    line-height: 130%;
    margin-bottom: 50px;
}

#Atitle {
    width: 280px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Atoptext {
    margin-bottom: 50px;
}

#Amiddletext {
    margin-bottom: 50px;
}

@media only screen and (max-width: 1050px) {
    #WWpartner {
        margin-left: 45px;
    }

    #BLUpartner {
        margin-right: 45px;
    }
}


@media only screen and (max-width: 950px) {
    #Atextwrapper {
        width: 600px;
        font-size: 22px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .partnerleft {
        display: none;
    }

    .partnerright {
        display: none;
    }

    #BLUpartner {

        width: 110px;
        height: 120px;
        margin-right: 30px;
    }
    
    #WWpartner {
        width: 120px;
        height: 110px;
        margin-left: 30px;
    }

    #WWtext {
        font-size: 20px;
        position: absolute;
        top: 50%;  /* position the top  edge of the element at the middle of the parent */
        left: 50%; /* position the left edge of the element at the middle of the parent */
        text-align: center;
        transform: translate(-50%, 170%);
    }
    
    #BLUtext {
        font-size: 20px;
        position: absolute;
        top: 50%;  /* position the top  edge of the element at the middle of the parent */
        left: 50%; /* position the left edge of the element at the middle of the parent */
        text-align: center;
        transform: translate(-50%, 170%);
    }

    
}

@media only screen and (max-width: 700px) {
    #Atextwrapper {
        width: 300px;
        font-size: 20px;
    }

    #Atoptext {
        margin-bottom: 20px;
    }
    
    #Amiddletext {
        margin-bottom: 20px;
    }
    

    .partnerleft {
        display: none;
    }

    .partnerright {
        display: none;
    }

    #logowrapper {
        margin-top: 0px;

    }

    .Ptoprow {
        margin-top: 0px;
        margin-bottom: 30px;
    }
}