.Dcontainer {
    width: 100vw;
    height: 330px;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
}

#Dtextwrapper {
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    font-size: 22px;
    font-family: CaledR;
}

#Dtoprow {
    margin-bottom: 40px;
}

#Dtoptext {
    margin-bottom: 8px;
    text-align: center;
}

#Dmiddletext {
    margin-bottom: 25px;
}

#Dbutton {
    height: 47px;
    width: 270px;
    background-image: linear-gradient(180deg, #BBA269 4.69%, #EFCB78 46.35%, #B59752 100%);
    color: black;
    font-size: 22px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: linear .5s;
    cursor: pointer;
    margin-bottom: 20px;
    transition: box-shadow ease-in-out .6s;
}

#Dbutton:hover {
    box-shadow: 0px 0px 10px 4px #8E887A;
}

@media only screen and (max-width: 950px) {
    .Dcontainer {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 700px) {
    .Dcontainer {
        margin-top: 20px;
    }
}